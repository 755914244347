export const lgSettlementRouter = [
  // 自由职业者管理
  {
    path: '/lgSettlement/freelancerMng',
    name: 'freelancerMng',
    component: () => import('../views/lgSettlement/freelancerMng')
  },
  // 企业项目管理
  {
    path: '/lgSettlement/enterProjectMng',
    name: 'enterProjectMng',
    component: () => import('../views/lgSettlement/enterProjectMng')
  },
  // 企业项目管理--详情
  {
    path: '/lgSettlement/enterProjectDtl',
    name: 'enterProjectDtl',
    component: () => import('../views/lgSettlement/enterProjectDtl')
  },
  // 企业账户管理
  {
    path: '/lgSettlement/lgAccountMng',
    name: 'lgAccountMng',
    component: () => import('../views/lgSettlement/lgAccountMng')
  },
  // 自由职业者合同管理
  {
    path: '/lgSettlement/freelancerContractMng',
    name: 'lgAuditMng',
    component: () => import('../views/lgSettlement/freelancerContractMng')
  },
  // 报酬发放管理
  {
    path: '/lgSettlement/lgPayMng',
    name: 'lgPayMng',
    component: () => import('../views/lgSettlement/lgPayMng')
  },
  // 服务费管理
  {
    path: '/lgSettlement/lgServiceFeeMng',
    name: 'lgServiceFeeMng',
    component: () => import('../views/lgSettlement/lgServiceFeeMng')
  },
  // 合作主体管理
  {
    path: '/lgSettlement/collaborativeSubjectMng',
    name: 'collaborativeSubjectMng',
    component: () => import('../views/lgSettlement/collaborativeSubjectMng')
  },
  // 充值订单管理
  {
    path: '/lgSettlement/rechargeOrderMng',
    name: 'rechargeOrderMng',
    component: () => import('../views/lgSettlement/rechargeOrderMng')
  },
  // 发票列表
  {
    path: '/lgSettlement/lgInvoiceList',
    name: 'lgInvoiceList',
    component: () => import('../views/lgSettlement/lgInvoiceList')
  }
]